:root {
  --int: 1.75;
  --scale0: 1.1rem;
  --scale-1: calc(var(--scale0) / var(--int));
  --scale-2: calc(var(--scale-1) / var(--int));
  --scale1: calc(var(--scale0) * var(--int));
  --scale2: calc(var(--scale1) * var(--int));
  --scale3: calc(var(--scale2) * var(--int));
  --scale4: calc(var(--scale3) * var(--int));
}

h1 {
  font-size: var(--scale3);
}
h2 {
  font-size: var(--scale2);
}
h3 {
  font-size: var(--scale1);
}

html {
  --primary-color: #292c34;
  --secondary-color: #09d4ac;
  --subtle-gray: #d0d0d099;

  font-size: var(--scale0);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--subtle-gray);
  min-height: 100vh;
}

.App-header {
  position: absolute;
  width: 100%;
  /* background: linear-gradient(to right, var(--primary-color) 0%, rgba(41, 44, 52, 0.75) 100%); */
  background: linear-gradient(to right, #09d4ac 0%, #09d 100%);
  height: 500px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
  display: flex;
  justify-content: center;
}

.header-content {
  max-width: 920px;
  width: 100%;

  display: flex;
  flex-direction: column;

  color: white;
  padding: 2rem;
}

.header-content-headline {
  margin-top: 0;
  margin-bottom: 0;
}

.header-content-tagline {
  margin: 0;
  margin-top: -0.75rem;
}

.header-description {
  font-weight: 700;
  margin: 0;
}

.header-content a {
  color: var(--primary-color);
  text-decoration: none;
}

.header-content a:hover {
  text-decoration: underline;
}

.books-container {
  margin-top: 25vh;
  max-width: 920px;
  display: flex;
  flex-wrap: wrap;
}

.book {
  background: white;

  border-radius: 10px;
  box-shadow: -2px 2px 5px 0 var(--primary-color);
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.book-photo img {
  width: 100%;
}

.book-info {
  margin: 0 1rem;
}

.book-info p {
  margin: 0.5rem 0;
}

.book-title {
  color: var(--primary-color);
  font-size: 1.5rem;
}

.card-face-back .book-info {
  margin-top: 0.5rem;
}

.card-face-back .book-title {
  color: #fff;
}

.book-author {
  color: darkgray;
  font-size: 1rem;
  /* use ellipsis for long author lists */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-face-back .book-author {
  color: lightgray;
}

.genres {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.genre-pill {
  border: solid 3px var(--secondary-color);
  border-radius: 6px;
  color: var(--secondary-color);
  font-size: 0.85rem;
  font-weight: 800;
  padding: 3px;
  margin-top: 0.5rem;
}

.genre-pill:not(:last-child) {
  margin-right: 0.5rem;
}

img.back-image {
  filter: saturate(0%);
  position: absolute;
  width: 500px;
  height: 500px;
  opacity: 0.2;
  transform: rotate(-20deg);
  top: 20%;
  left: 10%;
  z-index: -1;
}

/* Card Flip Styles */

.scene {
  flex-grow: 1;
  flex-basis: 46%;
  margin: 2%;
  width: 400px;
  height: 510px;
  /* width: 100%; */
  /* height: 100%; */
  perspective: 1000px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.card-face {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
}

.card-face-back {
  transform: rotateY( 180deg );
  color: #fff;
  background: linear-gradient(to right, var(--primary-color) 0%, rgba(41, 44, 52, 0.85) 100%);
}

.scene .card.is-flipped {
  transform: rotateY(180deg);
}

@media (max-width: 540px) {
  :root {
    --int: 1.5;
  }

  .header-content-headline {
    margin-bottom: 0.75rem;
  }

  .header-content-tagline {
    margin-bottom: 0.75rem;
  }

  .header-description {
  }
}

@media (max-width: 460px) {
  .scene {
    margin: 2% 4%;
    flex-grow: 1;
    flex-basis: 92%;
  }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

  .books-container {
    margin-top: 35vh;
    width: 96%;
  }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) {

  .books-container {
    margin-top: 30vh;
    width: 96%;
  }
}
